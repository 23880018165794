import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink as NavLinkRoute, useLocation } from "react-router-dom";
import { Collapse, Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { logo } from "../../../assets/images";
import './style.css';
import frontActions from "../../../redux/front/actions";

const {
  frontTestimonialsGet,
  fetchInventoryCount,
  fetchInventoryCountEbay,
} = frontActions;

const CommunityNavLink = () => {
  const location = useLocation()

  return (
    <UncontrolledDropdown inNavbar>
      <DropdownToggle nav caret tag="div" className={location.pathname.includes('/community/') ? 'active' : ''}>
        <NavLinkRoute
          to="/community/questions"
          className={`d-inline nav-link ${location.pathname.includes('/community/') ? 'active' : ''}`}
          onClick={(e) => e.stopPropagation()}
        >Community</NavLinkRoute>
      </DropdownToggle>
      <DropdownMenu className="bg-dark" right>
        <DropdownItem className="nav-link" target="_blank" href="https://www.accelerlist.com/amazon-acronyms-game">
          Amazon Acronym Game
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

const AffiliatesNavLink = () => {
  const location = useLocation()

  return (
    <NavLink href="/affiliates" active={location.pathname.includes('/affiliates')}>Affiliates 🔥</NavLink>
  )
}


class TopNav extends Component {
  state = {
    isOpen: false
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  componentDidMount = () => {
    this.props.frontTestimonialsGet();
    this.props.fetchInventoryCount();
    this.props.fetchInventoryCountEbay();
  }

  render() {
    console.log("userData", this.props.userData)

    return (
      <Navbar expand="lg" dark color="dark" container="md">
        <NavbarBrand href="/">
          <img src={logo} alt="AccelerList logo" />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ms-auto" navbar>
            <NavItem>
              <NavLink href="https://profithunt.io" target="_blank" rel="noopener noreferrer">Profit Hunt™</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/#pricing">Pricing</NavLink>
            </NavItem>
            <NavItem>
              <CommunityNavLink />
            </NavItem>
            <NavItem>
              <NavLinkRoute className="nav-link" to="/about_us" >About Us</NavLinkRoute>
            </NavItem>
            <NavItem>
              <NavLink href="https://www.accelerlist.com/blog" target="_blank" rel="noopener noreferrer">Blog</NavLink>
            </NavItem>
            <NavItem>
              <AffiliatesNavLink />
            </NavItem>
            <NavItem>
              <a
                className="btn btn-outline-primary btn-sm"
                href="https://app.accelerlist.com/signin"
              >AccelerList Login</a>
            </NavItem>
            <NavItem>
              <a
                className="btn btn-primary btn-sm"
                href="https://app.accelerlist.com/register"
              >Register</a>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}

export default connect(
  state => ({
    userData: state.User.get("userData"),
  }),
  {
    frontTestimonialsGet,
    fetchInventoryCount,
    fetchInventoryCountEbay,
  }
)(TopNav);
